"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const max_1 = require("libphonenumber-js/max");
function formatPhoneNumber(phone) {
  if (!phone) return '';
  if (!phone.startsWith('+')) phone = `+${phone}`;
  const asYouType = new max_1.AsYouType();
  asYouType.input(phone);
  const phoneTemplate = asYouType.getTemplate();
  const formattedValue = (0, max_1.formatIncompletePhoneNumber)(phone);
  const rawValue = formattedValue.replace(/\s/g, '');
  if (!phoneTemplate) {
    return rawValue;
  }
  const templateParts = phoneTemplate.split(' ');
  const phoneParts = formattedValue.split(' ');
  if (rawValue.length <= templateParts[0].length) {
    return rawValue;
  } else if (rawValue.length <= templateParts[0].length + templateParts[1].length) {
    return `${phoneParts[0]}(${phoneParts[1]}`;
  } else {
    let localParts = phoneParts.slice(2);
    let localPhone = localParts.join('-');
    return `${phoneParts[0]}(${phoneParts[1]})${localPhone}`;
  }
}
exports.default = formatPhoneNumber;