"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDateToServerWithoutTime = exports.formatDateToServer = exports.formatServerDate = exports.formatServerDateWithTime = exports.formatDateWithTime = exports.parseServerDate = exports.formatDate = exports.date = exports.haveIntersectionWithDateRanges = exports.PM = exports.AM = void 0;
const date_fns_1 = require("date-fns");
const locale_1 = require("date-fns/locale");
const dateFnsLocales = {
  ru: locale_1.ru
};
exports.AM = 'AM';
exports.PM = 'PM';
function haveIntersectionWithDateRanges(date, range) {
  if (!date || !range || range.length === 0) return false;
  const clearedDate = (0, date_fns_1.startOfDay)(date);
  for (const value of range) {
    if (value instanceof Date) {
      if ((0, date_fns_1.isEqual)(clearedDate, (0, date_fns_1.startOfDay)(value))) {
        return true;
      }
    } else if (value.start && !value.end && (0, date_fns_1.startOfDay)(value.start) <= clearedDate) {
      return true;
    } else if (!value.start && value.end && (0, date_fns_1.startOfDay)(value.end) >= clearedDate) {
      return true;
    } else if (value.start && value.end && (0, date_fns_1.startOfDay)(value.start) <= clearedDate && (0, date_fns_1.startOfDay)(value.end) >= clearedDate) {
      return true;
    }
  }
  return false;
}
exports.haveIntersectionWithDateRanges = haveIntersectionWithDateRanges;
function date(source) {
  if (!source) return null;
  if (!source.match(/.+\d\d:\d\d/)) {
    return (0, date_fns_1.parse)(source, 'dd.MM.yyyy', new Date());
  } else {
    return (0, date_fns_1.parse)(source, 'dd.MM.yyyy HH:mm', new Date());
  }
}
exports.date = date;
function formatDate(date) {
  let formatString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'd MMMM yyyy';
  let locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'ru';
  if (!date) return '';
  return (0, date_fns_1.format)(date, formatString, {
    locale: dateFnsLocales[locale]
  });
}
exports.formatDate = formatDate;
function parseServerDate(date) {
  if (!date) return null;
  return (0, date_fns_1.parseISO)(date);
}
exports.parseServerDate = parseServerDate;
function formatDateWithTime(date) {
  let formatString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'd MMMM yyyy, HH:mm';
  let locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'ru';
  if (!date) return '';
  return (0, date_fns_1.format)(date, formatString, {
    locale: dateFnsLocales[locale]
  });
}
exports.formatDateWithTime = formatDateWithTime;
function formatServerDateWithTime(date) {
  if (!date) return '';
  return formatDateWithTime(parseServerDate(date));
}
exports.formatServerDateWithTime = formatServerDateWithTime;
function formatServerDate(date) {
  if (!date) return '';
  return formatDate(parseServerDate(date));
}
exports.formatServerDate = formatServerDate;
function formatDateToServer(date) {
  if ((0, date_fns_1.isValid)(date)) {
    return (0, date_fns_1.format)(date, "yyyy-MM-dd'T'HH:mm");
  }
  return null;
}
exports.formatDateToServer = formatDateToServer;
function formatDateToServerWithoutTime(date) {
  if ((0, date_fns_1.isValid)(date)) {
    return (0, date_fns_1.format)(date, 'yyyy-MM-dd');
  }
  return null;
}
exports.formatDateToServerWithoutTime = formatDateToServerWithoutTime;